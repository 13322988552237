import React from 'react';

import './src/styles/reset.scss';
import './src/styles/variables.scss';
import './src/styles/global.scss';

import { PostCardsContextProvider } from './src/contexts/post-cards-context';
import { ModalContextProvider } from './src/contexts/modal-context';
import { IntroContextProvider } from './src/contexts/intro-context';
import { SlogansContextProvider } from "./src/contexts/slogans.context";

export const wrapRootElement = ({ element }) => {
    return (
        <PostCardsContextProvider>
            <SlogansContextProvider>
                {element}
            </SlogansContextProvider>
        </PostCardsContextProvider>
    );
};

export const wrapPageElement = ({ element }) => {
    return (
        <IntroContextProvider>
            <ModalContextProvider>{element}</ModalContextProvider>
        </IntroContextProvider>
    );
};
