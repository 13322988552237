module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Joho","short_name":"Joho","start_url":"/","background_color":"#4B4E9D","theme_color":"#4B4E9D","display":"standalone","icon":"/var/www/joho.pl/releases/6/frontend/src/assets/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"2a74f906b89780377f3cc6d78b97a571"},
    },{
      plugin: require('../plugins/gatsby-plugin-ap-framer-motion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-ap-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","defaultLanguage":"pl","languages":["pl"],"siteUrl":"https://joho.pl","redirect":false,"pagination":{"translations":{"pl":"strona"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-TL6XN4V","cookieName":"AP_ANALYTICS","dataLayerName":"dataLayer"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
