import React, { createContext, useContext, useState } from 'react';

import { IModal } from '../models/modal.model';

interface IModalContext {
    modal: null | IModal;
    addModal(modal: IModal): any;
    removeModal(): any;
}

const initialContext: IModalContext = {
    modal: null,
    addModal: () => {},
    removeModal: () => {},
};
const ModalContext = createContext(initialContext);

export const ModalContextProvider: React.FC = ({ children }) => {
    const [modal, setModal] = useState<IModal | null>(null);
    return (
        <ModalContext.Provider
            value={{
                modal,
                addModal: modal => setModal(modal),
                removeModal: () => setModal(null),
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => {
    const context = useContext(ModalContext);

    if (context === undefined) {
        throw new Error('useModalContext was used outside of its Provider');
    }

    return context;
};
