import React, { createContext, useContext, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { IQueryAllResult } from '../models/query-all-result.model';
import { getNodes } from '../utils/get-nodes';
import { ISlogan } from '../models/slogan.model';

interface ISlogansContext {
    slogans: ISlogan[];
}

interface ISlogansContextQueryResult {
    allSlogan: IQueryAllResult<ISlogan>;
}

const initialContext: ISlogansContext = {
    slogans: [],
};

const SlogansContext = createContext(initialContext);

export const SlogansContextProvider: React.FC = ({ children }) => {
    const { allSlogan }: ISlogansContextQueryResult = useStaticQuery(query);
    const slogansMemo = useMemo(() => {
        return getNodes(allSlogan);
    }, []);
    return (
        <SlogansContext.Provider
            value={{
                slogans: slogansMemo,
            }}
        >
            {children}
        </SlogansContext.Provider>
    );
};

export const useSlogansContext = () => {
    const context = useContext(SlogansContext);

    if (context === undefined) {
        throw new Error('useSlogansContext was used outside of its Provider');
    }

    return context;
};

export const useSlogans = (): ISlogan[] => {
    const { slogans } = useSlogansContext();
    return slogans;
};

const query = graphql`
    query {
        allSlogan {
            edges {
                node {
                    ...sloganFields
                }
            }
        }
    }
`;
